export const usePriceFormatter = (value: Number) => {
  if (value == null || value === undefined) { value = 0 }

  const formatter = new Intl.NumberFormat('ms-MY', {
    currencySign: 'standard',
    style: 'currency',
    currency: 'MYR'
  })

  const price = formatter.format(Number(value))

  return price
}
