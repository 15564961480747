export const useCapitalize = (str: string) => {
  if (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
  } else {
    throw createError({
      statusCode: 400,
      statusMessage: 'Please provide a string to capitalize'
    })
  }
}
