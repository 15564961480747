// ! DEPRECATED 07-10-2024, please use generateSellContractNo or generateBuyContractNo
export const useGenerateContractNo = async (contractHashID:string) => {
  try {
    const res = await $fetch('/api/trader/runningno')
    if (!res) { throw createError({ statusCode: 400, statusMessage: 'Problem to generate running no' }) }
    if (res.data === null || res.data === undefined) { throw createError({ statusCode: 400, statusMessage: 'Problem to generate running no' }) }
    const newRunningNo = (parseInt(res.data?._max.running_no) + 1).toString()
    const padRunningNo = newRunningNo.padStart(4, '0')
    const base = contractHashID.substring(2, contractHashID.length)
    const split = base.split('/') // array 0 = type, 1 = product code + running no, 2 = company
    const baseProductCodeRunningNo = split[1].substring(0, 7)

    const final = split[0] + '/' + baseProductCodeRunningNo + padRunningNo + '/' + split[2]

    const data = {
      contract_no: final,
      new_running_no: newRunningNo

    }
    return data
  } catch (error) {
    throw createError({
      statusCode: 400,
      statusMessage: 'Problem to generate running no'
    })
  }
}
