import { defineStore } from 'pinia'
// import { contract_type } from "@database/trader";

const contractType = {
  PURCHASE: 'PURCHASE',
  SALE: 'SALE'
}

export const useContractStore = defineStore('Contract', {
  persist: true,
  state: () => ({
    contract: {
      approved_contract_no: '',
      created_by: null,
      currency: 'RM',
      customer_hash_id: null,
      date_contract: new Date().toISOString(),
      date_shipment: new Date().toISOString(),
      hash_id: '',
      metric: 'unit',
      price_per_unit: 0,
      priceType1: 'priceMpobAvg',
      priceType2: undefined,
      contractLockStartDate: undefined,
      contractLockEndDate: undefined,
      product_id: null,
      quality: 'As per PORAM specification',
      quantity: 0,
      range: 'min',
      running_no: 0,
      status: 2,
      type: contractType.PURCHASE,
      unit: null,
      val: null,
      term: [],
      deliveryLocation: 'Kunak Refinery Sdn Bhd',
      productSpecType: 'poram',
      productSpecs: [
        {
          quality: 'Free Fatty Acid (FFA)',
          minVal: 0.00,
          maxVal: 0.00,
          unit: '%'
        },
        {
          quality: 'Moisture & Impurities (M&I)',
          minVal: 0.00,
          maxVal: 0.00,
          unit: '%'
        },
        {
          quality: 'DOBI',
          minVal: 0.00,
          maxVal: 0.00,
          unit: '%'
        }
        // ... more items
      ],
      indicativeCPOPrice: 0,
      indicativeOperationCost: 0,
      indicativeCogs: 0,
      discount: 0,
      incentive: 0
    },
    deco: {
      contract_type: 'PUR',
      date: '',
      mpob_avg_price: null,
      product_code: '',
      product_name: '',
      temp_no: 0,
      time: ''
    },
    others: {
      ent_id: null,
      ent_name: null,
      forecast_price: null,
      loading: false,
      mpob_avg_price_value: null,
      mpob_last_updated: null,
      mpob_price: null,
      user_id: null,
      user: null
    },
    pendingContractForGM: [],
    pendingContractForHOD: [],
    cancelledContract: [],
    approvedContract: [],
    approvedContractByHOD: [],
    approvalSummary: [],
    approvalSummaryTable: [],
    deliverySaleSummary: [],
    deliveryPurchaseSummary: [],
    transporterID: undefined,
    transporters: [],
    transporterVehicleID: undefined,
    transporterVehicles: [],
    transporterDriverID: undefined,
    transporterDrivers: [],
    key: 0
  }),
  actions: {
    setDecoDate (value: string) {
      const parts = value.match(/../g)
      const flippedStr = parts[1] + parts[0]
      this.deco.date = flippedStr
    },
    setDecoTime (value: string) {
      this.deco.time = value
    },
    setDecoTempNo (value: number) {
      this.deco.temp_no = value
    },
    setContractDate (value: any) {
      this.contract.date_contract = new Date(value).toISOString()
    },
    setDateShipment (value: any) {
      this.contract.date_shipment = new Date(value).toISOString()
    },
    setTerm (type: number, content: string) {
      this.contract.term.push({ type, content })
    },

    async addNewContract (status: number) {
      this.others.loading = true
      if (status > 2) {
        throw createError({
          statusCode: 404,
          statusMessage: 'status ID must less than 2 to create new contract'
        })
      }

      const { data } = await $fetch('/api/trader/contract', {
        method: 'POST',
        body: Object.assign({}, this.contract, this.others, this.deco)
      })
      return data
    },
    async getContracts (status: Number) {
      this.others.loading = true
      const res = await useFetch('/api/trader/contract', {
        query: { status } // submitted
      })
      this.others.loading = false
      return res
    },

    async getProducts (entHashID: string, isPublic: number) {
      const res = await useFetch('/api/inventory/products', {
        query: { ent_id: entHashID, is_public: isPublic }
      })

      return res
    },
    async setStatus (hashID: string, status: number) {
      this.others.loading = true
      if (status < 2) {
        throw createError({
          statusCode: 404,
          statusMessage: 'status ID must more than 2 to update status'
        })
      }

      try {
        const res = await useFetch('/api/trader/contract', {
          method: 'PUT',
          body: {
            hash_id: hashID,
            status
          }
        })
        this.others.loading = false
        return res
      } catch (error) {
        throw createError('Failed to set contract status')
      }
    },

    //! DEPRECATED 7/10/2024, use setApprovalByGmV1
    async setApprovalByGM (contractHashID:string, tempContractNo: string) {
      const websiteStore = useWebsiteStore()
      this.others.loading = true
      try {
        const { contract_no: contractNo, new_running_no: newRunningNo } = await useGenerateContractNo(
          tempContractNo
        )

        this.contract.approved_contract_no = contractNo

        const res = await $fetch('/api/trader/approval', {
          method: 'PUT',
          body: {
            contract_hash_id: contractHashID,
            temp_contract_no: tempContractNo,
            official_contract_no: contractNo,
            running_no: newRunningNo,
            status: 5,
            approved_by: websiteStore.user,
            approved_at: new Date().toISOString(),
            purpose: 'approved_by_gm'
          } // 5 = approved
        })
        this.others.loading = false

        return res
      } catch (error) {
        throw createError('Failed to make approval')
      }
    },

    async setApprovalByGmV1 (contractHashID:string, tempContractNo: string) {
      const websiteStore = useWebsiteStore()
      this.others.loading = true
      try {
        // TODO : replace with new generate running no

        let id = 0
        let contractNo = ''
        let newRunningNo = 0
        let type = ''

        if (tempContractNo.includes('SALE')) {
          const data = await this.generateSellContractNo(
            tempContractNo
          )

          id = parseInt(data.id)
          contractNo = data.contract_no
          newRunningNo = parseInt(data.new_running_no)
          type = 'sale'
        } else {
          const data = await this.generateBuyContractNo(
            tempContractNo
          )
          id = parseInt(data.id)
          contractNo = data.contract_no
          newRunningNo = parseInt(data.new_running_no)
          type = 'purchase'
        }

        this.contract.approved_contract_no = contractNo

        const res = await $fetch('/api/trader/approval', {
          method: 'PUT',
          body: {
            contract_hash_id: contractHashID,
            temp_contract_no: tempContractNo,
            official_contract_no: contractNo,
            running_no: newRunningNo,
            status: 5,
            approved_by: websiteStore.user,
            approved_at: new Date().toISOString(),
            purpose: 'approved_by_gm'
          } // 5 = approved
        })
        this.others.loading = false

        if (type === 'sale') {
          await this.updateSellContractNo(newRunningNo, id)
        } else {
          await this.updateBuyContractNo(newRunningNo, id)
        }

        return res
      } catch (error) {
        throw createError('Failed to make approval')
      }
    },
    async setApprovalByHOD (contractHashID:string) {
      this.others.loading = true
      const websiteStore = useWebsiteStore()
      try {
        const res = await $fetch('/api/trader/approval', {
          method: 'PUT',
          body: {
            contract_hash_id: contractHashID,
            status: 10,
            approved_by: websiteStore.user,
            approved_at: new Date().toISOString(),
            purpose: 'approved_by_hod'
          } // 10 = approved by hod
        })
        this.others.loading = false

        return res
      } catch (error) {
        throw createError('Failed to make approval')
      }
    },
    async save (
      hashID: string,
      pricePerUnit: number,
      quantity: number,
      dateShipment: string
    ) {
      this.others.loading = true
      try {
        const res = await $fetch('/api/trader/contractproduct', {
          method: 'PUT',
          body: {
            hash_id: hashID,
            price_per_unit: pricePerUnit,
            quantity,
            date_shipment: dateShipment
          }
        })
        this.others.loading = false
        return res
      } catch (error) {
        throw createError('Failed to save contract')
      }
    },
    async saveShipment (
      hashID: string,
      dateShipment: string,
      transporterID : string,
      transVehicleID : string
    ) {
      this.others.loading = true
      try {
        const res = await $fetch('/api/trader/contractproduct', {
          method: 'PUT',
          body: {
            hash_id: hashID,
            date_shipment: dateShipment,
            transporter_id: transporterID,
            trans_vehicle_id: transVehicleID
          }
        })
        this.others.loading = false
        return res
      } catch (error) {
        throw createError('Failed to save contract')
      }
    },
    async getEnterprise () {
      const { data } = await $fetch('/api/trader/enterprise')

      return data
    },
    setTempContractNo () {
      // set hash_id = T(TEMP)-contract_type+product_code+date+time+customer
      // set running_no = 0 to indicate it still temporary
      this.contract.hash_id =
        'T' +
        '-' +
        this.deco.contract_type +
        '/' +
        this.deco.product_code +
        this.deco.date +
        this.deco.time +
        '/' +
        this.others.ent_name
    },
    async getPendingContractForGM () {
      const websiteStore = useWebsiteStore()
      const formatter = new Intl.NumberFormat('ms-MY', {
        currencySign: 'standard',
        style: 'currency',
        currency: 'MYR'
      })

      this.others.loading = true
      const { data } = await $fetch('/api/trader/contractV01', {
        query: { status: 10 } // submitted
      })
      this.others.loading = false

      this.pendingContractForGM = data
        .filter(
          (item:any) =>
            (websiteStore.user?.role.includes('buy') &&
              item.type.toLowerCase() === 'purchase') ||
            (websiteStore.user?.role.includes('sell') &&
              item.type.toLowerCase() === 'sale') ||
              websiteStore.user?.role.includes('general-manager')
        )
        .map((item:any) => {
          const customCreatedAt =
            new Date(item.created_at).toLocaleString('en-US', {
              timeZone: 'Asia/Singapore'
            }) + ' GMT +08:00'
          const customType = item.type.toLowerCase()
          const customAmount = formatter.format(
            Number(item.contract_product.price_per_unit * item.contract_product.quantity)
          )
          const customPricePerUnit = formatter.format(
            Number(item.contract_product.price_per_unit)
          )
          const customQuantity = item.contract_product.quantity + ' Mt'

          return {
            ...item,
            custom_created_at: customCreatedAt,
            custom_type: customType,
            custom_amount: customAmount,
            custom_price_per_unit: customPricePerUnit,
            custom_quantity: customQuantity
          }
        })
    },
    async getPendingContractForHOD () {
      const websiteStore = useWebsiteStore()
      const formatter = new Intl.NumberFormat('ms-MY', {
        currencySign: 'standard',
        style: 'currency',
        currency: 'MYR'
      })

      this.others.loading = true
      const { data } = await $fetch('/api/trader/contractV01', {
        query: { status: 2 } // submitted
      })
      this.others.loading = false

      this.pendingContractForHOD = data
        .filter(
          (item:any) =>
            (websiteStore.user?.role.includes('marketing') &&
              item.type.toLowerCase() === 'purchase') ||
            (websiteStore.user?.role.includes('trading') &&
              item.type.toLowerCase() === 'sale') ||
              websiteStore.user?.role.includes('general-manager')
        )
        .map((item:any) => {
          const customCreatedAt =
            new Date(item.created_at).toLocaleString('en-US', {
              timeZone: 'Asia/Singapore'
            }) + ' GMT +08:00'
          const customType = item.type.toLowerCase()
          const customAmount = formatter.format(
            Number(item.contract_product.price_per_unit * item.contract_product.quantity)
          )
          const customPricePerUnit = formatter.format(
            Number(item.contract_product.price_per_unit)
          )
          const customQuantity = item.contract_product.quantity + ' Mt'

          return {
            ...item,
            custom_created_at: customCreatedAt,
            custom_type: customType,
            custom_amount: customAmount,
            custom_price_per_unit: customPricePerUnit,
            custom_quantity: customQuantity
          }
        })
    },
    async getCancelledContract () {
      const websiteStore = useWebsiteStore()
      const formatter = new Intl.NumberFormat('ms-MY', {
        currencySign: 'standard',
        style: 'currency',
        currency: 'MYR'
      })

      this.others.loading = true
      const { data } = await $fetch('/api/trader/contractV01', {
        query: { status: 4 } // rejected
      })
      this.others.loading = false

      this.cancelledContract = data
        .filter(
          item =>
            (websiteStore.user?.role.includes('buy') &&
              item.type.toLowerCase() === 'purchase') ||
            (websiteStore.user?.role.includes('sell') &&
              item.type.toLowerCase() === 'sale') ||
              websiteStore.user?.role.includes('general-manager')
        )
        .map((item:any) => {
          const customCreatedAt =
            new Date(item.created_at).toLocaleString('en-US', {
              timeZone: 'Asia/Singapore'
            }) + ' GMT +08:00'
          const customType = item.type.toLowerCase()
          const customAmount = formatter.format(
            Number(item.contract_product.price_per_unit * item.contract_product.quantity)
          )
          const customPricePerUnit = formatter.format(
            Number(item.contract_product.price_per_unit)
          )
          const customQuantity = item.contract_product.quantity + ' Metric ton '

          return {
            ...item,
            custom_created_at: customCreatedAt,
            custom_type: customType,
            custom_amount: customAmount,
            custom_price_per_unit: customPricePerUnit,
            custom_quantity: customQuantity
          }
        })
    },
    async getApprovedContract () {
      const websiteStore = useWebsiteStore()
      const formatter = new Intl.NumberFormat('ms-MY', {
        currencySign: 'standard',
        style: 'currency',
        currency: 'MYR'
      })

      this.others.loading = true
      const { data } = await $fetch('/api/trader/contractV01', {
        query: { status: 5, organization: websiteStore.user, department: websiteStore.user } // approved
      })

      this.approvedContract = data

        .map((item:any) => {
          const customCreatedAt =
            new Date(item.created_at).toLocaleString('en-US', {
              timeZone: 'Asia/Singapore'
            }) + ' GMT +08:00'
          const customType = item.type.toLowerCase()
          const customAmount = formatter.format(
            Number(item.contract_product.price_per_unit * item.contract_product.quantity)
          )
          const customPricePerUnit = formatter.format(
            Number(item.contract_product.price_per_unit)
          )
          const customQuantity = item.contract_product.quantity + ' Mt'

          const price = item.contract_product.price

          return {
            ...item,
            custom_created_at: customCreatedAt,
            custom_type: customType,
            custom_amount: customAmount,
            custom_price_per_unit: customPricePerUnit,
            custom_quantity: customQuantity,
            price
          }
        })
      this.others.loading = false
    },
    async sendNotificationNewContract (dept: string, ccManager: boolean) {
      try {
        let subject = ''
        let html = ''

        let tempTo = []
        let tempCC = []

        const { data } = await $fetch('/api/notification/recipient', {
          method: 'POST',
          body: {
            dept,
            cc_manager: ccManager
          }
        })

        if (data == null || data === undefined) {
          throw createError({
            statusCode: 500,
            statusMessage: 'failed to get recipient'
          })
        } else {
          tempTo = data.filter((item: any) =>
            item.position.includes('hod')
          )
          tempCC = data.filter(
            (item: any) => !item.position.includes('hod')
          )
          subject =
            'EOIOT - Trading Contract Pending for Approval Notification '
          html = `<p>To whom it may concern,</p>
          <p>There is a ${this.deco.product_name.toUpperCase()} ${this.contract.type.toUpperCase()} contract pending for approval. Login to EOIOT website to see the contract detail.</p>
          <br>
          <a href='https://globaliot.com.my/'>Login to EOIOT website</a>
          <br>
          <br>
          <p>Please do not reply to this email. This mailbox is not monitored and you will not receive a response.</p>`
        }

        const to = tempTo.map((item: any) => item.email)
        const cc = tempCC.map((item: any) => item.email)

        await useSendEmail(to, cc, subject, html, '', '')
      } catch (error) {

      }
    },
    async sendNotificationApprovedContract (contractData: any) {
      try {
        let subject = ''
        let html = ''
        const dept = contractData?.type === 'SALE' ? 6 : 5

        const officialContractNo = this.contract.approved_contract_no

        const { data } = await $fetch('/api/notification/recipient', {
          method: 'POST',
          body: {
            dept,
            cc_manager: true
          }
        })

        if (data == null || data === undefined) {
          throw createError({
            statusCode: 500,
            statusMessage: 'failed to get recipient'
          })
        } else {
          subject =
            "EOIOT - Sawit Kinabalu's Trader Contract Approval Notification "
          html = `<p>Dear <strong>Sir/Madam</strong>,</p>
          <br>
          <p>The contract for <strong>${officialContractNo}</strong> is enclosed in this email.</p>
          <br>
          <br>
          <p>Please do not reply to this email. This mailbox is not monitored and you will not receive a response.</p>`
        }

        const pdfData = {
          ...contractData,
          official_contract_no: officialContractNo
        }

        const to = contractData?.email
        const cc = data.map((item: any) => item.email)
        const attachmentName = `Contract_${
          contractData.name
        }_${officialContractNo.replace(/\//g, '')}.pdf`
        const attachmentContent = await useCreateContractPDF(
          pdfData,
          'output'
        )

        const res = await useSendEmail(
          to,
          cc,
          subject,
          html,
          attachmentName,
          attachmentContent
        )
        return res
      } catch (error) {
        throw createError({
          statusCode: 404,
          statusMessage: 'failed to send email'
        })
      }
    },
    async getApprovalSummary () {
      const websiteStore = useWebsiteStore()
      try {
        this.others.loading = true
        const res = await $fetch('/api/trader/approvalsummary', {
          method: 'POST',
          body: {
            // user: this.others.user
            user: websiteStore.user
          }
        })

        this.others.loading = false
        return res
      } catch (error) {
        throw createError({
          statusCode: 500,
          statusMessage: 'failed to get approval summary'
        })
      }
    },
    getApprovalSummaryForTable (data: Array<any>) {
      try {
        this.others.loading = true
        const temp = data.map(
          (item: {
            type: string;
            product_code: string;
            company_name: string;
            quantity: string;
            price: number;
            delivery: string;
          }) => {
            return {
              type: useCapitalize(item.type),
              product_code: item.product_code,
              company_name: useCapitalize(item.company_name),
              quantity: item.quantity + ' Mt',
              total_price: usePriceFormatter(item.price),
              date_shipment: new Date(item.delivery).toLocaleString('default', {
                month: 'long',
                year: 'numeric'
              })
            }
          }
        )
        this.others.loading = false
        return temp
      } catch (error) {
        throw createError({
          statusCode: 404,
          statusMessage: 'failed to calculate approval summary'
        })
      }
    },
    getDeliverySummary (data: Array<any>, type: string) {
      try {
        const currentMonth = new Date().getMonth() + 1 // get current month (0-indexed)
        const filteredData = data.filter((item: any) => {
          const itemMonth = new Date(item.delivery).getMonth() + 1 // get month from delivery date
          const isCurrentMonth = itemMonth === currentMonth // check if item is from current month
          const isSale = item.type === type // check if item is a sale
          return isCurrentMonth && isSale
        })

        const groupedData = filteredData.reduce((acc: any, item: any) => {
          const companyName = item.company_name
          if (!acc[companyName]) {
            acc[companyName] = {
              company_name: companyName,
              total_quantity: 0,
              total_price: 0
            }
          }
          acc[companyName].total_quantity += item.quantity
          acc[companyName].total_price += item.price
          return acc
        }, {})

        const flatGroupedData = Object.values(groupedData)

        const totalQty = flatGroupedData.map((item: any) => ({
          x: item.company_name,
          y: [0, item.total_quantity]
        }))
        return totalQty
      } catch (error) {
        throw createError({
          statusCode: 404,
          statusMessage: 'failed to calculate delivery summary'
        })
      }
    },
    async addSupplier (data: any) {
      const websiteStore = useWebsiteStore()
      this.others.loading = true
      const res = await $fetch('/api/trader/enterprise', {
        method: 'POST',
        body: Object.assign(data, { user: websiteStore.user }) // add user data
      })
      this.others.loading = false
      return res
    },
    async addDeliveryLocation (data: any) {
      const websiteStore = useWebsiteStore()
      this.others.loading = true
      const res = await $fetch('/api/trader/deliveryLocation', {
        method: 'POST',
        body: Object.assign(data, { user: websiteStore.user }) // add user data
      })
      this.others.loading = false
      return res
    },
    async addProduct (data: any) {
      const websiteStore = useWebsiteStore()
      this.others.loading = true
      const res = await $fetch('/api/trader/product', {
        method: 'POST',
        body: Object.assign(data, { user: websiteStore.user }) // add user data
      })
      this.others.loading = false
      return res
    },
    async getContactPerson (entHashID: string) {
      const { data } = await $fetch('/api/trader/customer', {
        method: 'GET',
        query: {
          ent_hash_id: entHashID
        }
      })

      return data
    },
    async getDeliveryLocation () {
      const { data } = await $fetch('/api/trader/deliveryLocation')
      return data
    },
    removeProductSpec (index: number) {
      this.contract.productSpecs.splice(index, 1)
    },
    addProductSpec () {
      this.contract.productSpecs.push({ quality: '', minVal: 0.00, maxVal: 0.00, unit: '%' })
    },
    async addTransporter (data:{}) {
      const websiteStore = useWebsiteStore()
      websiteStore.loading = true
      const res = await $fetch('/api/trader/transporter', {
        method: 'POST',
        body: data
      })
      websiteStore.loading = false
      return res
    },
    async getTransporter (entHashID:string) {
      const { data } = await $fetch('/api/trader/transporter', {
        method: 'GET',
        query: {
          ent_hash_id: entHashID
        }
      })

      this.transporters = data
      return data
    },
    async getTransporterVehicle (transporterID:string) {
      const { data } = await $fetch('/api/trader/transportervehicle', {
        method: 'GET',
        query: {
          transporter_id: transporterID
        }
      })

      this.transporterVehicles = data
    },
    async addTransporterVehicle (data:{}) {
      const websiteStore = useWebsiteStore()
      websiteStore.loading = true
      const res = await $fetch('/api/trader/transportervehicle', {
        method: 'POST',
        body: data
      })
      websiteStore.loading = false
      return res
    },
    async editSupplier (data: any) {
      const websiteStore = useWebsiteStore()
      this.others.loading = true
      const res = await $fetch('/api/trader/enterprise', {
        method: 'PUT',
        body: Object.assign(data, { user: websiteStore.user }) // add user data
      })
      this.others.loading = false
      return res
    },
    async generateSellContractNo (contractHashID:string) {
      try {
        const res = await $fetch('/api/ver/1.0/trader/runningno')
        if (!res) { throw createError({ statusCode: 400, statusMessage: 'Problem to generate running no' }) }
        if (res.data === null || res.data === undefined) { throw createError({ statusCode: 400, statusMessage: 'Problem to generate running no' }) }

        let runningNo = 0
        if (res.data.starting_number > res.data.current_number) {
          runningNo = res.data?.starting_number
        } else {
          runningNo = res.data?.current_number
        }
        const newRunningNo = (parseInt(runningNo) + 1).toString()
        const padRunningNo = newRunningNo.padStart(res.data?.zerofill, '0')
        const base = contractHashID.substring(2, contractHashID.length)
        const split = base.split('/') // array 0 = type, 1 = product code + running no, 2 = company
        const baseProductCodeRunningNo = split[1].substring(0, 7)

        const final = split[0] + '/' + baseProductCodeRunningNo + padRunningNo + '/' + split[2]

        const data = {
          contract_no: final,
          new_running_no: newRunningNo,
          id: res.data?.id

        }
        return data
      } catch (error) {
        throw createError({
          statusCode: 400,
          statusMessage: 'Problem to generate running no'
        })
      }
    },
    async generateBuyContractNo (contractHashID:string) {
      try {
        const res = await $fetch('/api/ver/1.0/marketing/runningno')
        if (!res) { throw createError({ statusCode: 400, statusMessage: 'Problem to generate running no' }) }
        if (res.data === null || res.data === undefined) { throw createError({ statusCode: 400, statusMessage: 'Problem to generate running no' }) }

        let runningNo = 0
        if (res.data.starting_number > res.data.current_number) {
          runningNo = res.data?.starting_number
        } else {
          runningNo = res.data?.current_number
        }
        const newRunningNo = (parseInt(runningNo) + 1).toString()
        const padRunningNo = newRunningNo.padStart(res.data?.zerofill, '0')
        const base = contractHashID.substring(2, contractHashID.length)
        const split = base.split('/') // array 0 = type, 1 = product code + running no, 2 = company
        const baseProductCodeRunningNo = split[1].substring(0, 7)

        const final = split[0] + '/' + baseProductCodeRunningNo + padRunningNo + '/' + split[2]

        const data = {
          contract_no: final,
          new_running_no: newRunningNo,
          id: res.data?.id

        }
        return data
      } catch (error) {
        throw createError({
          statusCode: 400,
          statusMessage: 'Problem to generate running no'
        })
      }
    },
    async updateSellContractNo (newNumber:string, rowID:number) {
      try {
        const res = await $fetch('/api/ver/1.0/trader/runningno', {
          method: 'PUT',
          body: { row_id: rowID, new_number: newNumber }
        })
        return res
      } catch (error) {
        throw createError({
          statusCode: 400,
          statusMessage: 'Problem to update new running no'
        })
      }
    },
    async updateBuyContractNo (newNumber:string, rowID:number) {
      try {
        const res = await $fetch('/api/ver/1.0/marketing/runningno', {
          method: 'PUT',
          body: { row_id: rowID, new_number: newNumber }
        })
        return res
      } catch (error) {
        throw createError({
          statusCode: 400,
          statusMessage: 'Problem to update new running no'
        })
      }
    }

  }
})
