import moment from 'moment'
import { jsPDF } from 'jspdf'
import capitalize from 'lodash/capitalize.js'
import letterhead from '~/assets/img/letterhead.jpg'
import signatureT from '~/assets/img/signature_trading.png'
import signatureM from '~/assets/img/signature_marketing.png'
import stamp from '~/assets/img/stamp.png'

export const useCreateContractPDF = async (
  data: any,
  action: 'download' | 'print' | 'output'
) => {
  try {
    const printJs = await import('print-js')

    // Default export is a4 paper, portrait, using millimeters for unit
    const doc = new jsPDF()

    const y = 0 // 3.5 from top
    const x = 20 // 0.5 from left side
    let trackY = y
    const maxHeight = 297
    const maxWidth = 210
    const fontsize = 10
    const spaceAdj1 = 4
    const spaceAdj2 = 6

    const attachmentName = data.name.replace(/\//g, '') + '.pdf'
    let contractNo = null

    if (data.hasOwnProperty('official_contract_no')) {
      contractNo = data.official_contract_no
    } else {
      contractNo = data.name
    }

    // data processing
    const approvedDateTemp =
      data.approved_at === null
        ? new Date().toISOString().substring(0, 10)
        : data.approved_at
    const approvedDate = new Date(approvedDateTemp)
      .toISOString()
      .substring(0, 10)

    const country = data.enterprise.country_code === 'my' ? 'Malaysia' : ''
    const contractType = data.type === 'PURCHASE' ? 'bought' : 'sold'
    const others =
      'As Per Standard PORAM/MPOA Domestic contract for Malaysian RBD Palm Olein in Bulk Contractual Terms currently in force to govern'

    const others2 =
      'All other terms and conditions shall be as per the PORAM / MPOA Domestic contract for Malaysian Crude Unbleached Palm Oil in Bulk currently in force'

    const priceType = data.contract_term[1].content === 'priceSpot' ? 'Spot Price' : ''

    const productName = data.contract_product.product.name === 'Crude Palm Oil' ? 'Malaysian Crude Unbleached Palm Oil in Bulk' : data.contract_product.product.name
    // header
    doc.addImage(letterhead, 'JPEG', 0, 0, maxWidth, maxHeight)
    doc
      .setFont('times', '', 'bold')
      .setFontSize(14)
      .text('Our Contract No: ' + contractNo, x, y + 60)

    doc
      .setFont('times', '', 'normal')
      .setFontSize(fontsize)
      .text(moment(approvedDate).format('Do MMMM YYYY'), x, y + 50)
    doc
      .setFont('times', '', 'bold')
      .setFontSize(fontsize)
      .text(data.enterprise.legal_name.toUpperCase(), x, y + 70)
    doc
      .setFont('times', '', 'normal')
      .setFontSize(fontsize)
      .text(data.enterprise?.address_line1, x, y + 75)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(

        data.enterprise.postal_code +
          ' ' + data.enterprise.city
        ,
        x,
        y + 80
      )
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(
        data.enterprise.state_province +
          ' ' +
          country,
        x,
        y + 85
      )

    // content
    doc
      .setFont('times', 'italic')
      .setFontSize(fontsize)
      .text('Kind Attn : Mr.' + data.customer.first_name + ' ' + data.customer.last_name, x, y + 95)
    doc
      .setFont('times', '')
      .setFontSize(fontsize)
      .text(
        `We confirm having ${contractType} to you today the following:-`,
        x,
        y + 105
      )

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('1) Commodity', x, y + 115)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, y + 115)

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(String(productName), x + 60, y + 115)

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('2) Specifications', x, y + 123)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, y + 123)

    trackY = y + 123
    data.contract_spec.forEach((element:any) => {
      if (element.quality === 'As per PORAM specification') {
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('The Crude Palm Oil shall be of the agreed description and', x + 60, trackY)
        trackY += spaceAdj1
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('specifications at the time of delivery as follow', x + 60, trackY)
        trackY += spaceAdj1
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('Free Fatty Acid (FFA)', x + 60, trackY)
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('-', x + 103, trackY)
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('5.00% Max', x + 110, trackY)
        trackY += spaceAdj1
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('Moisture & Impurities (M&I)', x + 60, trackY)
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('-', x + 103, trackY)
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('0.25% Max', x + 110, trackY)
        trackY += spaceAdj1
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('DOBI', x + 60, trackY)
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('-', x + 103, trackY)
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('Min. 2.31 Load Basis', x + 110, trackY)
        trackY += spaceAdj1
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('All other specifications as per PORAM/MPOA domestic contract for Malaysia', x + 60, trackY)
        trackY += spaceAdj1
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text('Crude Unbleached Palm Oil in Bulk', x + 60, trackY)
      } else {
        doc
          .setFont('times')
          .setFontSize(fontsize)
          .text(`${element.quality} ${element.range === null ? '' : element.range} ${element.unit === null ? '' : element.unit}`, x + 60, trackY)
        trackY += spaceAdj1
      }
    })

    trackY += spaceAdj2
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('3) Quantity', x, trackY)

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(
        data.contract_product.quantity + ' ' + useMetricFormatter(data.contract_product.metric),
        x + 60,
        trackY
      )

    trackY += spaceAdj2

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('4) Delivery', x, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('During ' + moment(data.date_shipment).format('MMMM YYYY'), x + 60, trackY)
    trackY += spaceAdj2

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('5) Quality/Weight', x, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)
    if (contractType === 'bought') {
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text(
          'Final at Buyer`s Factory',
          x + 60,
          trackY
        )
    } else {
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text(
          'Shipped Weight and Shipped Quality Final at Load Port',
          x + 60,
          trackY
        )
    }

    trackY += spaceAdj2
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('6) Price', x, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)
    if (contractType === 'bought' && priceType !== 'Spot Price') {
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text(
          moment(data.date_shipment).format('MMMM YYYY') + ' Monthly MPOB Peninsular Malaysia Average Less RM40.00',
          x + 60,
          trackY
        )
      trackY += spaceAdj2
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text(
          'Per Metric Ton Plus Incentives RM40.00 Per Metric Ton',
          x + 60,
          trackY
        )
    } else {
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text(
          data.custom_price_per_unit + ' per ' + useMetricFormatter(data.contract_product.metric) + ` (${priceType})`,
          x + 60,
          trackY
        )
    }

    trackY += spaceAdj2

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('7) Payment', x, trackY, { maxWidth: 100 })
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(data.contract_term[0].content, x + 60, trackY, { maxWidth: 100 })

    trackY += spaceAdj2 + 5

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('8) Others', x, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)

    if (contractType === 'bought') {
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text(others2, x + 60, trackY, { maxWidth: 100 })
    } else {
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text(others, x + 60, trackY, { maxWidth: 100 })
    }

    trackY += spaceAdj2 + 10

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('9) Basis', x, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('Delivered to ' + data.contract_shipment?.option_shipment?.legal_name + ' ' + data.contract_shipment?.option_shipment?.address_line1 + ' ' + data.contract_shipment?.option_shipment?.address_line2 + ' ' + data.contract_shipment?.option_shipment?.postal_code + ' ' + data.contract_shipment?.option_shipment?.city + ' ' + data.contract_shipment?.option_shipment?.state_province, x + 60, trackY, { maxWidth: 100 })

    trackY += spaceAdj2 + 5

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text('10) Mill Name', x, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(':', x + 50, trackY)
    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(capitalize(data.enterprise.legal_name), x + 60, trackY)

    trackY += spaceAdj2

    doc
      .setFont('times')
      .setFontSize(fontsize)
      .text(
        'Please stamp,sign, and return to us the duplicate copy of this contract',
        x,
        trackY
      )

    trackY += 5

    // signature
    if (data.type === 'PURCHASE') {
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text('As Buyer :', x, trackY)
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text('KUNAK REFINERY SDN BHD', x, trackY + 30)
      doc
        .setFont('times')
        .setFontSize(fontsize)
        .text('As Seller :', x + 120, trackY)
      doc
        .setFont('times', '', 'bold')
        .setFontSize(fontsize)
        .text(data.enterprise?.legal_name.toUpperCase(), x + 120, trackY + 30)
    } else {
      doc
        .setFont('times', '', 'normal')
        .setFontSize(fontsize)
        .text('As Seller :', x, trackY)
      doc
        .setFont('times', '', 'bold')
        .setFontSize(fontsize)
        .text('KUNAK REFINERY SDN BHD', x, trackY + 30)
      doc
        .setFont('times', '', 'normal')
        .setFontSize(fontsize)
        .text('As Buyer :', x + 120, trackY)
      doc
        .setFont('times', '', 'bold')
        .setFontSize(fontsize)
        .text(data.enterprise.legal_name?.toUpperCase(), x + 120, trackY + 30)
    }

    trackY -= 5

    if (contractType === 'bought') {
      doc.addImage(signatureM, 'PNG', 15, trackY - 10, 50, 50)
    } else {
      doc.addImage(signatureT, 'PNG', 15, trackY - 10, 50, 50)
    }

    doc.addImage(stamp, 'PNG', 45, trackY, 30, 30)

    trackY += 30
    doc.setLineWidth(0.01).line(x, trackY, x + 30, trackY)
    doc.setLineWidth(0.01).line(x + 120, trackY, x + 150, trackY)
    trackY += 10
    doc
      .setFont('times', '', 'normal')
      .setFontSize(12)
      .text('cc. Ketua Pengarah MPOB :', x, trackY + 5)
    trackY += 20
    doc
      .setFont('times', '', 'bold')
      .setFontSize(14)
      .setTextColor(255, 255, 255)
      .text('KUNAK REFINERY SDN BHD', x + 30, 280)
    doc
      .setFont('times', '', 'normal')
      .setFontSize(11)
      .setTextColor(255, 255, 255)
      .text('Co No 199501025809 (355014-W)', x + 95, 280)
    doc
      .setFont('times', '', 'normal')
      .setFontSize(11)
      .setTextColor(255, 255, 255)
      .text('Block B, Menara Kinabalu, Jalan Sulaman, Teluk Likas, 88500 Kota Kinabalu, Sabah', x + 20, 285)
    doc
      .setFont('times', '', 'normal')
      .setFontSize(11)
      .setTextColor(255, 255, 255)
      .text('Tel: +6088-235811', x + 70, 290)

    const uri = doc.output('bloburi')
    switch (action) {
      case 'download':
        doc.save(attachmentName)
        break
      case 'print':
        printJs.default({ printable: uri, type: 'pdf' })
        break
      case 'output':
        return doc.output('datauristring')
    }
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: 'cannot create pdf'
    })
  }
}
