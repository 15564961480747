export const useSendEmail = async (
  to: string[],
  cc: string[],
  subject: string,
  html:string,
  attachmentName: string,
  attachmentContent: any

) => {
  try {
    const { data } = await $fetch('/api/notification/email', {
      method: 'POST',
      body: {
        to,
        cc,
        subject,
        html,
        attachment_name: attachmentName,
        attachment_content: attachmentContent
      }
    })

    return data
  } catch (error) {

  }
}
